import { Statistic } from "antd";
import React from "react";
import "./Category.scss";

interface CategoryProps {
    title: string;
    count: string;
}

const Category: React.FC<CategoryProps> = (props) => {
    return (
        <div className="category">
            <Statistic title={props.title} value={props.count} />
        </div>
    );
};

export default Category;
