class Http {
    post = async (url: string, body: any) => {
        const json = await fetch(`/api/${url}`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return await json.json();
    };

    get = async (url: string, body: any) => {
        const json = await fetch(
            `/api/${url}?${new URLSearchParams(body).toString()}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        return await json.json();
    };
}

const http = new Http();
export default http;
