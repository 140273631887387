import "./Loading.scss";

interface LoadingProps {
    message?: string;
}

const Loading = (props: LoadingProps) => {
    return (
        <div className="loading_wrapper" style={{ color: "#eaeaea" }}>
            <i className="fas fa-circle-notch fa-spin fa-4x"></i>
            {props.message ? <h3>{props.message}</h3> : null}
        </div>
    );
};

export default Loading;
