import { Input } from "antd";
import { PureComponent } from "react";
import SearchGrid from "@/components/SearchGrid/SearchGrid";
import cn from "classnames";
import "./Home.scss";
import { search_placeholder } from "@/Globals";

interface HomeProps {
    loading: boolean;
    result: any;
    error: string;
    searchQuery?: string;
    onChange?: (e: any) => void;
    onSearch?: () => void;
}

class Home extends PureComponent<HomeProps> {
    render() {
        const { result, error } = this.props;
        return (
            <div className="Home" style={{ color: "#eaeaea" }}>
                <div className={cn("logo", { hidden: result })}></div>
                {error ? <div className="error">{error}</div> : null}
                <div className="search-area">
                    <Input
                        allowClear
                        variant="borderless"
                        placeholder={search_placeholder}
                        onPressEnter={this.props.onSearch}
                        onChange={this.props.onChange}
                        value={this.props.searchQuery}
                        className="search-title"
                    />
                </div>
                {result ? (
                    <div className="search">
                        <h2>
                            {result.results.length === 0
                                ? "Couldn't find that title 😢"
                                : "Search results"}
                        </h2>
                        <SearchGrid search_result={result} />
                    </div>
                ) : (
                    <div className="centered"></div>
                )}
            </div>
        );
    }
}

export default Home;
