import React, { PureComponent } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Movie from "./components/Media/Movie/Movie";
import Header from "./components/Header/Header";
import WithRouter from "./components/WithRouter";
import "./App.scss";
import Api from "./Logic/Api";
import NotFound from "./components/NotFound/NotFound";
//import Test from "./components/Test";
import Show from "./components/Media/Show/Show";

interface State {
    searchQuery: string;
    loading: boolean;
    result: any;
    error: string;
}

class App extends PureComponent<{ navigate: (to: string) => void }, State> {
    state: State = {
        searchQuery: "",
        loading: false,
        result: null,
        error: null,
    };

    handleSearch = async () => {
        if (this.state.searchQuery) {
            if (window.location.pathname !== "/") {
                this.props.navigate("/");
            }
            //@ts-ignore
            document.querySelector(".search-title input").blur();
            this.setState({ loading: true });
            const result = await Api.find(this.state.searchQuery);
            this.setState({ loading: false, result });
        }
    };

    handleChange = (e: any) => {
        const value = e.target.value;
        this.setState({ searchQuery: value });
    };

    componentDidMount() {
        //@ts-ignore
        document.querySelector(".search-title input").focus();
    }

    render() {
        const { loading, result, searchQuery, error } = this.state;
        return (
            <div className="app">
                {window.location.pathname !== "/" ? (
                    <Header
                        onChange={this.handleChange}
                        onSearch={this.handleSearch}
                        searchQuery={searchQuery}
                    />
                ) : null}
                <Routes>
                    <Route path="*" element={<NotFound />} />
                    <Route
                        path="/"
                        element={
                            <Home
                                result={result}
                                loading={loading}
                                error={error}
                                onChange={this.handleChange}
                                onSearch={this.handleSearch}
                                searchQuery={searchQuery}
                            />
                        }
                    />
                    <Route path="/movie/:id" element={<Movie />} />
                    <Route path="/show/:id" element={<Show />} />
                    {/* <Route path="/test" element={<Test />} /> */}
                </Routes>
            </div>
        );
    }
}

export default WithRouter(App);
