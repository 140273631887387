import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./Movie.scss";
import Loading from "@/components/Loading/Loading";
import _ from "lodash";
import Api from "@/Logic/Api";
import Centered from "@/components/Centered/Centered";
import Tags from "../Tags/Tags";
import LoadImage from "@/components/LoadImage/LoadImage";
import Categories from "../Categories/Categories";
import { product_name } from "@/Globals";

const Movie = () => {
    const { id } = useParams<"id">();
    const [movieDetails, setMovieDetails] = useState(null);
    const [error, setError] = useState(null);
    const [mediaTitle, setMediaTitle] = useState("");

    useEffect(() => {
        const getData = async () => {
            //@ts-ignore
            const result = await Api.getData({ id, media_type: "movie" });

            if (result?.error) {
                if (result?.error?.status === 404) {
                    setError(`A title with ID: ${id} is not found!`);
                    return;
                }
                setError(result.error);
                return;
            }

            setMovieDetails(result);

            const mt = `${
                result.metadata.title
            } (${result.metadata.release_date?.substring(0, 4)})`;
            setMediaTitle(mt);

            document.title = `${mt} - ${product_name}`;
        };

        getData();

        // eslint-disable-next-line
    }, []);

    if (error) {
        return <Centered size="extreme">{error}</Centered>;
    }

    const noProfanityFound = _.isEmpty(movieDetails?.profanity);

    const genres = movieDetails?.metadata?.genres;

    return movieDetails ? (
        <div
            className="media-wrapper"
            style={
                movieDetails && movieDetails.images.backdrop
                    ? {
                          background: `url(${movieDetails.images.backdrop})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                      }
                    : undefined
            }
        >
            <div className="media" style={{ color: "#eaeaea" }}>
                <div className="poster">
                    <LoadImage
                        width={185}
                        height={278}
                        src={movieDetails.images.poster_small}
                        preview={movieDetails.images.poster_large}
                    />
                </div>
                <h1 className="media-title">{mediaTitle}</h1>
                <div className="content">
                    <Tags profanity={movieDetails.profanity} list={genres} />
                    <div className="media-overview">
                        {movieDetails.metadata.overview}
                    </div>
                    <Categories
                        mediaDetails={movieDetails}
                        noProfanityFound={noProfanityFound}
                    />
                </div>
            </div>
        </div>
    ) : (
        <Loading message="Loading" />
    );
};

export default Movie;
