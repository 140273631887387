import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider, theme } from "antd";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <ConfigProvider
        theme={{
            algorithm: theme.darkAlgorithm,
            token: { colorTextHeading: "#ffffff" },
        }}
    >
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ConfigProvider>
);
