import Http from "./Http";

class Api {
    find = (query: string) => {
        return Http.get("find", { query });
    };

    getData = ({
        id,
        only,
        media_type,
    }: {
        id: string;
        only?: "metadata";
        media_type: "movie" | "tv";
    }) => {
        return Http.post(`get-${media_type}-data`, { id, only });
    };
}

const api = new Api();

export default api;
