import React, { PureComponent } from "react";
import { tmdb_search_result } from "@/Types";
import SearchResult from "../SearchResult/SearchResult";
import "./SearchGrid.scss";

interface SearchGridProps {
    search_result: {
        page: number;
        results: tmdb_search_result[];
        total_pages: number;
        total_results: number;
    };
}

class SearchGrid extends PureComponent<SearchGridProps> {
    render() {
        return (
            <div className="search-grid">
                {this.props.search_result.results
                    .filter((media) => media.media_type === "movie")
                    .map((media) => (
                        <SearchResult key={media.id} media={media} />
                    ))}
            </div>
        );
    }
}

export default SearchGrid;
