import React from "react";
import cn from "classnames";
import "./Centered.scss";

interface CenteredProps {
    children: any;
    size?: "large" | "extreme";
}

const Centered: React.FC<CenteredProps> = (props: CenteredProps) => {
    return (
        <div
            style={{ color: "#eaeaea" }}
            className={cn("centered", props.size)}
        >
            {props.children}
        </div>
    );
};

export default Centered;
