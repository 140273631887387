import React, { useState } from "react";
import { tmdb_search_result } from "@/Types";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import "./SearchResult.scss";
import { Modal, Tag } from "antd";
import Api from "@/Logic/Api";
import LoadImage from "../LoadImage/LoadImage";

interface SearchResultProps {
    media: tmdb_search_result;
}

const SearchResult = (props: SearchResultProps) => {
    const { media } = props;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleClick = async (media_type: "movie" | "tv") => {
        setLoading(true);
        const error = (message: string) => {
            Modal.warning({ content: message });
        };
        const res = await Api.getData({
            id: media.id.toString(),
            only: "metadata",
            media_type,
        });
        if (res.error) {
            error(res.error);
            return;
        }
        navigate(`/${media_type === "tv" ? "show" : "movie"}/${res.id}`);
        setLoading(false);
    };

    let overview = media?.overview;

    if (overview.length > 203) {
        overview = `${media?.overview.substring(0, 200)}...`;
    }

    const release_date =
        media.media_type === "movie"
            ? media?.release_date?.substring(0, 4)
            : media?.first_air_date?.substring(0, 4) || null;

    return (
        <div
            className={cn("movie-result", { working: loading })}
            onClick={() => handleClick(media.media_type)}
        >
            {loading ? (
                <div className="loading">
                    <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                </div>
            ) : null}
            <div className="movie-result__poster">
                <LoadImage
                    src={`https://image.tmdb.org/t/p/w92/${media?.poster_path}`}
                    alt={`${media.title} poster`}
                    width={92}
                    height={138}
                />
                <div className="movie-result__poster__tags">
                    <Tag
                        className="capitalize-first-letter media-type-tag"
                        color={
                            media.media_type === "movie" ? "geekblue" : "gold"
                        }
                    >
                        {media.media_type === "tv" ? "Show" : media.media_type}
                    </Tag>
                </div>
            </div>
            <div className="movie-result__details">
                <h3 className="movie-result__details__title">
                    {`${media?.title || media?.original_name} ${
                        release_date ? `(${release_date})` : ""
                    }`}
                </h3>
                <div className="movie-result__details__overview">
                    {overview}
                </div>
            </div>
        </div>
    );
};

export default SearchResult;
