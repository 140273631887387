import React from "react";
import "./NotFound.scss";

interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps> = () => {
    return (
        <div className="not-found-404">
            <h1>404</h1>
            <h2>
                "Well, what if there is no webpage? There wasn't one today."
                <p>Groundhog Day (1993)</p>
            </h2>
        </div>
    );
};

export default NotFound;
