import React from "react";
import Category from "./Category";
import _ from "lodash";
import "./Categories.scss";

interface CategoriesProps {
    mediaDetails: any;
    noProfanityFound: any;
}

const Categories: React.FC<CategoriesProps> = ({
    mediaDetails,
    noProfanityFound,
}) => {
    let countAllProfanity = 0;
    return !mediaDetails.profanity.error && !noProfanityFound ? (
        <div className="categories">
            {Object.keys(mediaDetails.profanity).map((category) => {
                const catObj = mediaDetails.profanity[category];
                let catData = { amount: 0 };
                _.forEach(catObj, (cat) => (catData.amount += cat.amount));
                countAllProfanity += catData.amount;
                return (
                    <Category
                        key={category}
                        title={category}
                        count={catData.amount.toString()}
                    />
                );
            })}
            <Category
                key="perMin"
                title="Profanity per minute"
                count={(
                    countAllProfanity / mediaDetails.metadata.runtime
                ).toFixed(2)}
            />
        </div>
    ) : null;
};

export default Categories;
