import { Tag } from "antd";
import _ from "lodash";
import React from "react";
import "./Tags.scss";

interface TagsProps {
    profanity: any;
    list?: { id: number; name: string }[];
}

const Tags: React.FC<TagsProps> = (props) => {
    const error = props.profanity.error;
    const noProfanityFound = _.isEmpty(props.profanity);
    return error || noProfanityFound || props.list ? (
        <div className="tags">
            {error ? (
                <Tag title={error} color="red">
                    {error}
                </Tag>
            ) : null}
            {noProfanityFound ? (
                <Tag color="green">No profanity found</Tag>
            ) : null}
            {props.list
                ? props.list.map((tag) => {
                      return (
                          <Tag color="purple" key={`${tag.id}.${tag.name}`}>
                              {tag.name}
                          </Tag>
                      );
                  })
                : null}
        </div>
    ) : null;
};

export default Tags;
