import React, { useState } from "react";
import { Image, Skeleton } from "antd";

interface LazyLoadImageProps {
    width: number;
    height: number;
    src: string;
    alt?: string;
    title?: string;
    preview?: string;
}

const LazyLoadImage: React.FC<LazyLoadImageProps> = ({
    width,
    height,
    src,
    alt,
    title,
    preview,
}) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <>
            <Image
                src={src}
                title={title}
                alt={alt}
                width={imageLoaded ? width : 0}
                height={imageLoaded ? height : 0}
                style={{
                    display: imageLoaded ? "block" : "none",
                }}
                onLoad={() => setImageLoaded(true)}
                preview={preview ? { src: preview } : false}
            />
            {imageLoaded ? null : (
                <Skeleton.Image style={{ width, height }} active />
            )}
        </>
    );
};

export default LazyLoadImage;
