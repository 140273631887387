import { Input } from "antd";
import React from "react";
import { search_placeholder } from "@/Globals";
import "./Header.scss";

interface HeaderProps {
    searchQuery: string;
    onChange: (e: any) => void;
    onSearch: () => void;
}

const Header: React.FC<HeaderProps> = (props) => {
    return (
        <div className="header">
            <div className="input-wrapper">
                <Input
                    allowClear
                    variant="borderless"
                    placeholder={search_placeholder}
                    onPressEnter={props.onSearch}
                    onChange={props.onChange}
                    value={props.searchQuery}
                    className="search-title"
                />
            </div>
        </div>
    );
};

export default Header;
